const config = {
	name: 'Mustafa Ekicim',
	title: "Hi I'm Mustafa Ekicim 👋🏻",
	blog: 'Medium', // Dev.to or Medium (Make blank if you don't need the blog)
	// Social media profile username
	github: 'mstfe', // [REQUIRED] Github Username
	twitter: 'edice', // Twitter Username
	stackoverflow: '1528214', // Eg: 3211944
	medium: 'mustafae', // without @ symbol
	instagram: 'bymstf',
	dev: '',
	youtube: '', // Channel ID
	linkedin: 'mustafaekicim'
};

export { config };
